<!------------------------------------------- template ------------------------------------------->
<template>
  <div class="checkin">
    <van-icon name="warning-o" size="64px" color="rgb(255, 148, 58)"/>
    <div class="checkin-title">签到失败</div>
    <div class="checkin-desc">参会人数超过限制，请联系客服</div>
  </div>
</template>

<!-------------------------------------------- script -------------------------------------------->
<script>
export default {
  name: '',
  components: {
  },

  data () {
    return {
    }
  },

  /* 一.生命周期函数 */
  created () {
  },

  /* 二.监控函数 */
  watch: {
  },

  computed: {
  },

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 事件调用函数注释
    actionXxx () {

    },

    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    doSomething () {

    },

    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxXxx () {

    }
  }
}
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
.checkin{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  &-title{
    margin-top: 20px;
    font-size: 16px;
  }
  &-desc{
    color: #666;
    margin-top: 12px;
    font-size: 14px;
  }
}
</style>
